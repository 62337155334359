.container {
    padding: 20px !important;
    border-radius: 50px !important;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2) !important;
}

.icon {
    height: 50px;
    width: 50px;
    filter: invert(100%);
}