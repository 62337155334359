.container {
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
    z-index: 999;
}

.card {
    position: absolute;
    padding: 2px 10px;
    height: 80vh;
    width: 30vw;
    color: white;
    border-radius: 1rem;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 999;
}

@media only screen and (max-width: 600px) {
    .card {
        width: 90vw;
    }
}